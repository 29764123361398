import Model, { attr } from '@ember-data/model';

export default class AnnouncementModel extends Model {
  @attr('string') fromLabel;
  @attr('string') message;
  @attr('string') title;

  @attr('date') createdAt;
  @attr('date') readAt;
}
