/* import __COLOCATED_TEMPLATE__ from './home-hero-unit.hbs'; */
import { service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@tagName('')
export default class HomeHeroUnit extends Component {
  @service currentPractice;
}
