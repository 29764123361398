/* import __COLOCATED_TEMPLATE__ from './license-agreement.hbs'; */
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './license-agreement.module.scss';

@classic
@classNames(styles.component)
export default class LicenseAgreement extends Component {
  hasAgreed = false;

  @service currentPractice;

  @action
  toggleAgreed() {
    this.toggleProperty('hasAgreed');
  }
}
