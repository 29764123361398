import { buildValidations, validator } from 'ember-cp-validations';
import { reads } from '@ember/object/computed';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';

const Validations = buildValidations({
  address: [
    validator('presence', {
      presence: true,
      disabled: reads('model.skipValidation'),
      ignoreBlank: true,
    }),
    validator('format', {
      type: 'email',
      allowBlank: true,
    }),
  ],
});

@classic
export default class EmailModel extends Model.extend(Validations) {
  @attr() address;
}
