import { STEP_IDS } from 'client-portal/services/widget-steps';
import { action } from '@ember/object';
import { service } from '@ember/service';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';
import uuidv4 from 'uuid/v4';

@classic
export default class RequestService extends RequestBaseRoute {
  @service analytics;
  @service mixpanel;
  @service session;
  @service store;
  @service request;
  @service pendingAppointment;
  templateName = '-request/service';
  stepId = STEP_IDS.SELECT_SERVICE;

  beforeModel() {
    let reservation = this.modelFor(this.request.baseRouteName);
    if (reservation && !reservation.belongsTo('clinician').id()) {
      this.request.transitionTo(this, 'clinician');
    }
  }

  async model(params, transition) {
    let model;
    let reservation = this.modelFor(this.request.baseRouteName);
    if (!reservation) {
      return;
    }

    if (this.hasShoebox) {
      model = this.store.peekAll('cpt-code');
    } else {
      let { queryParams } = transition.to;
      if (queryParams.cptCodeId) {
        transition.trigger(false, 'preselectionLoading');
      }

      model = await this.findServices(reservation, transition);
    }

    this.request.set('cptCodes', model);

    return model;
  }

  afterModel(model, transition) {
    super.afterModel(...arguments);

    if (model && !model.get('length')) {
      this.request.transitionTo(this, 'unavailable', {
        queryParams: { reason: 'reservation does not exist' },
      });
    }

    this.checkChannelService(model);

    if (this.skipStepWithPreselection(model, transition, 'cptCodeId')) return;
    this.pendingAppointment.reset();
  }

  shouldSelectChannelService(channel) {
    return channel?.widgetSelectChannelService;
  }

  checkChannelService(model) {
    let channel = this.modelFor(this.request.baseRouteName).get('channel');

    if (this.shouldSelectChannelService(channel)) {
      let selectedService = model.findBy('globalMonarchChannel', channel);

      if (selectedService) {
        this.nextStep(selectedService);
      }
    }
  }

  findServices(reservation, transition) {
    let clinicianId = reservation.get('clinician.id');
    let globalMonarchChannelId = reservation.get('channel.id');
    let include = undefined;
    let { searchByOffice, officeId } = transition.to.queryParams;
    let filter = {
      clinicianId,
      ...(searchByOffice && officeId ? { officeId } : {}),
      ...(globalMonarchChannelId ? { globalMonarchChannelId } : {}),
      ...(this.session.isMonarchEmbedded ? { callToBook: false } : {}),
    };
    if (globalMonarchChannelId) {
      include = 'globalMonarchChannel';
    }

    return this.store.query('cpt-code', { filter, include });
  }

  @action
  nextStep(cptCode, params) {
    super.nextStep('cptCode', cptCode, 'location', params);
  }

  @action
  didTransition() {
    let reservation = this.modelFor(this.request.baseRouteName);
    if (!reservation) {
      return;
    }

    reservation.setProperties({
      cptCode: null,
      office: null,
      startTime: null,
      clientType: null,
      message: null,
      hasCompletedMessageStep: false,
      channelFields: null,
      channelUploads: null,
      channelUploadsComplete: null,
      hasCompletedInformationStep: false,
      flowId: reservation.flowId || uuidv4(),
    });

    this._trackAppointmentStep(reservation);
    return true;
  }
}
