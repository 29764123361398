import { ACTIVE_FEEDBACK_BANNER_ID } from 'client-portal/utils/feedback-banner-events';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class SiteAppointmentsRoute extends Route {
  @service router;
  @service session;
  @service clientTimezone;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    this.clientTimezone.reset();
    this.session.requireAuthentication(transition, 'home');

    if (!this.session.currentClient.permissions.appointments) {
      this.router.transitionTo('site.home');
    }
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('activeFeedbackBannerId', ACTIVE_FEEDBACK_BANNER_ID);
  }
}
