/* import __COLOCATED_TEMPLATE__ from './office-staticmap.hbs'; */
import {
  buildStaticMapUrl,
  buildStaticMapWithCircleUrl,
} from 'ember-simplepractice/utils/google-maps';
import { importAsset } from 'client-portal/helpers/import-asset';
import Component from '@glimmer/component';
import ENV from 'client-portal/config/environment';
import formatZipcode from 'client-portal/helpers/format-zipcode';

const MARKER_ICON_OFFICE_PATH = '/assets/images/map-marker-office.png';
const MARKER_ICON_PIN_PATH = '/assets/images/map-marker-pin.png';
const ZOOM_LEVEL_PUBLIC = 17;
const ZOOM_LEVEL_PRIVATE = 12;

export default class SharedOfficeStaticmap extends Component {
  markerColor = '333333'; // $color-neutral-800
  markerIconOfficePath = importAsset([MARKER_ICON_OFFICE_PATH]);
  markerIconPinPath = importAsset([MARKER_ICON_PIN_PATH]);
  zoomLevelPublic = ZOOM_LEVEL_PUBLIC;
  zoomLevelPrivate = ZOOM_LEVEL_PRIVATE;

  get office() {
    return this.args.office || {};
  }

  get isPublic() {
    return this.office.isPublic;
  }

  get addressForAlt() {
    let showStreet = this.isPublic && this.office.street;
    let addr = `${this.office.city}, ${this.office.state} ${formatZipcode.compute([
      this.office.zip,
    ])}`;
    return showStreet ? `${this.office.street} ${addr}` : addr;
  }

  get alt() {
    return `A street map for office location at ${this.addressForAlt}`;
  }

  get src() {
    return this.isPublic ? this.publicMapSrc : this.hiddenMapSrc;
  }

  get desktopSrc() {
    if (!this.args.sizeDesktop) {
      return null;
    }

    return this.isPublic ? this.publicMapSrcDesktop : this.hiddenMapSrcDesktop;
  }

  get markerIconUrl() {
    if (['development', 'test'].includes(ENV.environment)) {
      return undefined;
    }

    let path = this.isPublic ? this.markerIconPinPath : this.markerIconOfficePath;
    return encodeURIComponent(path);
  }

  get imgLoadingValue() {
    return ENV.railsEnv === 'test' ? 'eager' : 'lazy';
  }

  get publicMapSrc() {
    return this._buildPublicMapSrc(this.args.size);
  }

  get publicMapSrcDesktop() {
    return this._buildPublicMapSrc(this.args.sizeDesktop);
  }

  get hiddenMapSrc() {
    return this._buildHiddenMapSrc(this.args.size);
  }

  get hiddenMapSrcDesktop() {
    return this._buildHiddenMapSrc(this.args.sizeDesktop);
  }

  _buildHiddenMapSrc(size) {
    if (!this.office.geolocation) {
      return undefined;
    }

    return buildStaticMapWithCircleUrl({
      size,
      key: ENV.googleMapsKey,
      lat: this.office.geolocation.lat,
      lng: this.office.geolocation.lng,
      markerUrl: this.markerIconUrl,
      markerColor: this.markerColor,
      zoom: this.zoomLevelPrivate,
    });
  }

  _buildPublicMapSrc(size) {
    return buildStaticMapUrl({
      size,
      key: ENV.googleMapsKey,
      markerColor: this.markerColor,
      markerLocation: this.office.mapMarkerLocation,
      markerUrl: this.markerIconUrl,
      zoom: this.zoomLevelPublic,
    });
  }
}
