/* import __COLOCATED_TEMPLATE__ from './contact-info-form.hbs'; */
import { action } from '@ember/object';
import { bannerError, callbackError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { reads } from '@ember/object/computed';
import { relationshipTypes } from 'client-portal/utils/relationship-types';
import { scrollToValidationError } from 'client-portal/utils/validate-record';
import { service } from '@ember/service';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Super from './autosave-form';
import classic from 'ember-classic-decorator';
import stateGroups from 'ember-simplepractice/utils/state-groups';
import styles from './contact-info-form.module.scss';

const ERROR_POINTER_PREFIX = '/data/attributes/contactInfoRequestConnection.';

const WORK = 'Work';
const HOME = 'Home';

@classic
@classNames(styles.component)
export default class SiteDocumentsContactInfoForm extends Super {
  @service session;
  styles = styles;

  stateGroups = stateGroups;
  emailTypes = [HOME, WORK];
  phoneTypes = ['Mobile', WORK, HOME, 'Fax'];
  sexes = ['Female', 'Male'];
  relationshipStatuses = {
    '': '',
    'Civil union': 'Civil union',
    'Divorced': 'Divorced',
    'Domestic Partnership': 'Domestic partnership',
    'Married': 'Married',
    'Non-monogamous': 'Non-monogamous',
    'In long-term Relationship': 'Partnered',
    'Separated': 'Separated',
    'Single': 'Single',
    'Widowed': 'Widowed',
  };
  employmentStatuses = {
    '': '',
    'Full-Time': 'Full-time',
    'Homemaker': 'Homemaker',
    'Part-Time': 'Part-time',
    'Retired': 'Retired',
    'Self-employed': 'Self-employed',
    'Full-time Student': 'Student (full-time)',
    'Part-time Student': 'Student (part-time)',
    'Unemployed': 'Unemployed',
  };
  insuredRelations = {
    0: 'Client',
    1: 'Client’s Spouse',
    2: 'Client’s Parent',
    3: 'Other',
  };
  emergencyContactTypes = relationshipTypes;

  @reads('model.connection') connection;

  @reads('connection') autosaveRecord;

  @action
  setReminders(attrName, consentAttrName, valueOrEvent) {
    let value = typeof valueOrEvent === 'object' ? valueOrEvent.target.checked : valueOrEvent;
    this.connection.set(attrName, value);
    if (value) {
      this.connection.set(consentAttrName, true);
    }
  }

  @action
  setPhoneNumber(attrName, value) {
    this.connection.set(attrName, value);
  }

  @action
  async nextStep() {
    try {
      if (this.connection.validations.isInvalid) {
        await waitForRender();
        this.connection.set('isDirty', true);
        scrollToValidationError();
        return;
      }

      await this.completeRequestTask.perform(this.model, {
        adapterOptions: {
          embed: ['connection'],
        },
      });
      this.set('autosaveData', {});
      this.connection.rollbackAttributes();
    } catch (err) {
      if (!this.connection) {
        return;
      }

      callbackError(err, message => {
        if (message.status !== '422') {
          bannerError(err, { title: `${this.model.documentTitle} could not be submitted` });
        }
        return true;
      });

      // Attach nested errors to the connection
      err.errors.forEach(({ source, title }) => {
        if (!source || source.pointer.indexOf(ERROR_POINTER_PREFIX) !== 0) {
          return;
        }

        let attr = source.pointer.replace(ERROR_POINTER_PREFIX, '');
        this.connection.errors.add(attr, title);
      });

      await waitForRender();
      scrollToValidationError();
    }
  }
}
