export default function styleInject(css) {
  if (!css || typeof document === 'undefined') return; // don't error out if using SSR

  if (window._DISABLE_STYLE_INJECT === false) return; // allow turning off injection
  let INSERT_BEFORE_ID = 'style-inject-insert-before'; // id of element in head where styles should be injected before
  let head = document.head || document.getElementsByTagName('head')[0];

  // an empty style tag that is inserted at the top of the head and which all
  // further styles are inserted before. This fixes the reverse insertion issue
  // reported in https://github.com/egoist/style-inject/issues/23
  let elementToInsertBefore = document.getElementById(INSERT_BEFORE_ID);

  // if this is the first injected style and there is no user-defined element to insert before,
  // we must create the insert-before element that we will use for all further injections
  if (elementToInsertBefore === null) {
    elementToInsertBefore = document.createElement('style');
    elementToInsertBefore.id = INSERT_BEFORE_ID; // if there is already stuff in the head, put this first

    if (head.querySelector('link[rel="stylesheet"]')) {
      let before = head.querySelector('link[rel="stylesheet"]').nextSibling;
      head.insertBefore(elementToInsertBefore, before);
    } else if (head.firstChild) {
      head.insertBefore(elementToInsertBefore, head.firstChild);
    } else {
      // if the head is empty, just insert this
      head.appendChild(elementToInsertBefore);
    }
  }

  let style = document.createElement('style');
  style.type = 'text/css';
  head.insertBefore(style, elementToInsertBefore);

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}
