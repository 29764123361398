import { and, reads } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';
import { service } from '@ember/service';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

const Validations = buildValidations({
  addressCity: [validator('ds-error')],
  addressState: [validator('ds-error')],
  addressStreet: [validator('ds-error')],
  addressZip: [validator('ds-error')],
  birthDate: [validator('ds-error')],
  eligibleInsuranceProviderName: [validator('ds-error')],
  emailAddress: [
    validator('format', {
      type: 'email',
      message: 'is invalid',
      allowBlank: true,
      disabled: reads('model.isCouple'),
    }),
    validator('ds-error'),
  ],
  emergencyContactEmailAddress: [
    validator('format', {
      type: 'email',
      allowBlank: true,
      message: 'is invalid',
    }),
    validator('ds-error'),
  ],
  emergencyContactFirstName: [validator('ds-error')],
  emergencyContactLastName: [validator('ds-error')],
  emergencyContactPhoneNumber: [validator('ds-error')],
  emergencyContactType: [validator('ds-error')],
  employmentStatus: [validator('ds-error')],
  firstName: [validator('ds-error')],
  genderInfo: [validator('ds-error')],
  insuranceGroupId: [validator('ds-error')],
  insuranceInsuredAddress: [validator('ds-error')],
  insuranceInsuredCity: [validator('ds-error')],
  insuranceInsuredDob: [validator('ds-error')],
  insuranceInsuredFname: [validator('ds-error')],
  insuranceInsuredLname: [validator('ds-error')],
  insuranceInsuredPhone: [validator('ds-error')],
  insuranceInsuredRelation: [validator('ds-error')],
  insuranceInsuredSex: [validator('ds-error')],
  insuranceInsuredState: [validator('ds-error')],
  insuranceInsuredZip: [validator('ds-error')],
  insuranceMemberId: [validator('ds-error')],
  insurancePlanId: [validator('ds-error')],
  insuranceEffectiveStartDate: [validator('ds-error')],
  insuranceEffectiveEndDate: [validator('ds-error')],
  isMinor: [validator('ds-error')],
  lastName: [validator('ds-error')],
  middleName: [validator('ds-error')],
  nickname: [validator('ds-error')],
  phoneNumber: [validator('ds-error')],
  relationshipStatus: [validator('ds-error')],
  sex: [validator('ds-error')],
});

@classic
export default class DocumentRequestContactInfoConnectionModel extends Model.extend(Validations) {
  @service store;
  @service session;

  @reads('session.currentClient.isCouple') isCouple;

  @belongsTo('insurance-provider-name', { async: false }) eligibleInsuranceProviderName;
  @hasMany('insurance-card', { async: false }) insuranceCards;
  @attr('boolean') askForNickname;
  @attr('boolean') askForGenderIdentity;
  @attr('boolean') askForInsurance;
  @attr('boolean') practiceEnableEmailReminders;
  @attr('boolean') practiceEnableSmsReminders;
  @attr('boolean') practiceEnableVoiceReminders;
  @attr() firstName;
  @attr() lastName;
  @attr() middleName;
  @attr() nickname;
  @attr('boolean') isMinor;
  @attr() emailAddress;
  @attr() emailType;
  @attr('boolean') emailEmailConsentGiven;
  @attr('boolean') enableEmailReminders;
  @attr() phoneNumber;
  @attr() phoneType;
  @attr('boolean') phoneLeaveVoiceMessage;
  @attr('boolean') phoneLeaveTextMessage;
  @attr() enableSmsvoiceReminders;
  @attr() addressStreet;
  @attr() addressCity;
  @attr() addressState;
  @attr() addressZip;
  @attr('moment') birthDate;
  @attr() sex;
  @attr() genderInfo;
  @attr() relationshipStatus;
  @attr() employmentStatus;
  @attr() emergencyContactFirstName;
  @attr() emergencyContactLastName;
  @attr() emergencyContactType;
  @attr() emergencyContactPhoneNumber;
  @attr() emergencyContactPhoneType;
  @attr('boolean') emergencyContactPhoneLeaveVoiceMessage;
  @attr('boolean') emergencyContactPhoneLeaveTextMessage;
  @attr() emergencyContactEnableSmsvoiceReminders;
  @attr() emergencyContactEmailAddress;
  @attr() emergencyContactEmailType;
  @attr('boolean') emergencyContactEmailEmailConsentGiven;
  @attr('boolean') emergencyContactEnableEmailReminders;
  @attr() insuranceMemberId;
  @attr() insuranceGroupId;
  @attr() insurancePlanId;
  @attr() insuranceInsuredRelation;
  @attr() insuranceInsuredFname;
  @attr() insuranceInsuredLname;
  @attr('moment') insuranceInsuredDob;
  @attr() insuranceInsuredSex;
  @attr() insuranceInsuredPhone;
  @attr() insuranceInsuredAddress;
  @attr() insuranceInsuredCity;
  @attr() insuranceInsuredState;
  @attr() insuranceInsuredZip;
  @attr() insuranceCardFrontId;
  @attr() insuranceCardBackId;
  @attr('moment') insuranceEffectiveStartDate;
  @attr('moment') insuranceEffectiveEndDate;

  @and('eligibleInsuranceProviderName', 'insuranceMemberId') hasRequiredInsuranceInfo;

  get autosaveAttrs() {
    let attrs = [];
    this.constructor.fields.forEach((_, name) => attrs.push(name));
    attrs.removeObject('askForNickname');
    attrs.removeObject('askForGenderIdentity');
    attrs.removeObject('askForInsurance');
    attrs.removeObject('practiceEnableEmailReminders');
    attrs.removeObject('practiceEnableSmsReminders');
    attrs.removeObject('practiceEnableVoiceReminders');
    attrs.removeObject('insuranceCards');
    return attrs;
  }

  autosaveSerialize() {
    let data = {};
    this.autosaveAttrs.forEach(name => {
      if (name === 'eligibleInsuranceProviderName' && this.get(name)) {
        return (data[name] = this.get(name).serialize({ includeId: true }));
      }

      data[name] = this.get(name);
    });
    return data;
  }

  autosaveRestore(data) {
    this.autosaveAttrs.forEach(name => {
      // eslint-disable-next-line no-prototype-builtins
      if (!data.hasOwnProperty(name)) {
        return;
      }

      if (
        name === 'eligibleInsuranceProviderName' &&
        data[name] &&
        typeof data[name] === 'object'
      ) {
        this.store.pushPayload(data[name]);
        return this.set(name, this.store.peekRecord('insurance-provider-name', data[name].data.id));
      }

      if (
        [
          'birthDate',
          'insuranceInsuredDob',
          'insuranceEffectiveStartDate',
          'insuranceEffectiveEndDate',
        ].includes(name)
      ) {
        return this.set(name, moment.parseZone(data[name]));
      }

      this.set(name, data[name]);
    });
  }
}
