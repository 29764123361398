import { bool, empty, equal, filterBy, not, or, reads } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';
import { computed } from '@ember/object';
import { isBlank } from '@ember/utils';
import { isoDateFormat } from 'client-portal/utils/date-time';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AddressableMixin from 'client-portal/mixins/polymorphic/addressable';
import Model, { attr, hasMany } from '@ember-data/model';
import PhoneableMixin from 'client-portal/mixins/polymorphic/phoneable';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

export const BIRTH_DATE_VALIDATOR = [
  validator('presence', {
    presence: true,
    disabled: reads('model.skipBirthDatePresenceValidation'),
  }),
  validator('inline', {
    validate(value) {
      if (!value || value.isBefore(moment())) return true;

      return 'Please enter a valid date of birth.';
    },
  }),
];

const Validations = buildValidations({
  sex: [
    validator('presence', {
      presence: true,
      disabled: or('model.skipSexPresenceValidation', 'model.skipClaimValidations'),
    }),
  ],
  insuranceInfos: [
    validator('has-many', {
      disabled: reads('model.skipClaimValidations'),
    }),
  ],
  addresses: [
    validator('has-many', {
      disabled: or('model.sexPresenceValidationEnabled', 'model.skipClaimValidations'),
    }),
  ],
});

export const NO_INSURANCE_TYPE = 0;
export const HAS_INSURANCE_TYPE = 1;

@classic
export default class BaseClientModel extends Model.extend(
  Validations,
  PhoneableMixin,
  AddressableMixin
) {
  @service request;

  @hasMany({ async: false, inverse: null }) clientRelationships;
  @hasMany('insurance-info', { async: false }) insuranceInfos;
  @hasMany('phone', { async: false, inverse: null }) phones;
  @hasMany('office', { async: false, inverse: null }) addresses;
  @attr() email;
  @attr() firstName;
  @attr() hashedId;
  @attr() initials;
  @attr('boolean', { defaultValue: false }) isMinor;
  @attr() lastName;
  @attr() middleName;
  @attr() nickname;
  @attr() preferredName;
  @attr('json') permissions;
  @attr() phoneNumber;
  @attr() sex;
  @attr('moment', { format: isoDateFormat }) birthDate;
  @attr() signatoryName;
  @attr() relationshipToCurrentClient;
  @attr() relationshipToCurrentClientAccess;
  @attr('number', { defaultValue: NO_INSURANCE_TYPE }) billingType;

  @filterBy('insuranceInfos', 'thisType', 'primary') primaryInsuranceInfos;
  @reads('primaryInsuranceInfos.firstObject') primaryInsuranceInfo;
  @bool('primaryInsuranceInfo.hasRequiredFields') hasInsuranceDetails;
  @reads('addresses.firstObject') defaultAddress;
  @reads('phones.firstObject') defaultPhone;
  @not('skipSexPresenceValidation') sexPresenceValidationEnabled;
  @not('skipClaimValidations') claimValidationsEnabled;

  @tracked isDirty = false;
  @tracked skipSexPresenceValidation = false;
  @tracked skipClaimValidations = true;

  skipBirthDatePresenceValidation = false;
  insuranceCardFrontSideFile = null;
  insuranceCardBackSideFile = null;

  @computed('firstName', 'nickname')
  get firstNickname() {
    return isBlank(this.nickname) ? this.firstName : this.nickname;
  }

  @computed('firstName', 'lastName', 'sex', 'birthDate', 'defaultAddress', 'defaultPhone')
  get hasRequiredFields() {
    let requiredFields = [
      this.firstName,
      this.lastName,
      this.sex,
      this.birthDate,
      this.defaultAddress,
      this.defaultPhone,
    ];

    return requiredFields.every(Boolean);
  }

  @empty('clientRelationships') hasNoClientRelationships;
  @equal('clientRelationships.length', 1) hasOneClientRelationship;

  get allValidationErrors() {
    return this.validations.errors
      .concat(this.defaultPhone.validations.errors)
      .concat(this.defaultAddress.validations.errors);
  }

  updateSignature(signatoryName, relationship) {
    this.setProperties({
      signatoryName,
      relationshipToCurrentClient: relationship === 'client' ? null : relationship,
    });
  }

  disableBirthDatePresenceValidation() {
    this.set('skipBirthDatePresenceValidation', true);

    if (this.hasOneClientRelationship) {
      this.clientRelationships.firstObject.relatedClient.set(
        'skipBirthDatePresenceValidation',
        true
      );
    }
  }
}
