/* import __COLOCATED_TEMPLATE__ from './feedback-banner.hbs'; */
import { action, computed, set } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { events } from 'client-portal/utils/feedback-banner-events';
import { isIphone } from 'client-portal/utils/mobile-detect';
import { modifier } from 'ember-modifier';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { waitForLater } from 'ember-simplepractice/utils/waiters';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './feedback-banner.module.scss';

const FOCUS_SCROLL_DELAY = 100;

@classic
@classNames(styles.component)
export default class FeedbackBanner extends Component {
  @service analytics;
  @service currentPractice;
  @service feedbackBanner;
  @service mixpanel;
  @service store;
  @service session;

  bannerRef = null;
  inputRef = false;
  events = events;

  @reads('feedbackBanner.currentClient') currentClient;
  @reads('feedbackBanner.permissions') permissions;
  @reads('feedbackBanner.mixpanelEventData') mixpanelEventData;
  @reads('feedbackBanner.clientType') clientType;

  @reads('feedbackBanner.states') states;
  @reads('feedbackBanner.currentStateId') currentStateId;

  @reads('feedbackBanner.setStates') setStates;
  @reads('feedbackBanner.clearStates') clearStates;
  @reads('feedbackBanner.setCurrentStateId') setCurrentStateId;

  @computed('surveyToDisplay.body')
  get body() {
    return this.surveyToDisplay?.body;
  }

  @computed('surveyToDisplay.header')
  get header() {
    return this.surveyToDisplay?.header;
  }

  @computed('surveyToDisplay.class')
  get stateClass() {
    return this.surveyToDisplay?.class;
  }

  @computed('surveyToDisplay.next')
  get nextState() {
    return this.surveyToDisplay?.next;
  }

  @computed('surveyToDisplay.model')
  get model() {
    return this.surveyToDisplay?.model;
  }

  @computed('states', 'currentStateId')
  get surveyToDisplay() {
    let { states, currentStateId } = this;
    return currentStateId ? states.findBy('id', currentStateId) : null;
  }

  @computed('header', 'body')
  get isShowing() {
    return this.header || this.body;
  }

  init() {
    super.init(...arguments);

    this.onVisualViewportResize = this.onVisualViewportResize.bind(this);
    this.setupEvents();
  }

  onSetupAndTeardown = modifier(
    element => {
      this.bannerRef = element;
      if (isIphone()) {
        // This is to ward off iphone specific scaling and sizing behavior.
        this.setupViewListeners();
      }

      return () => {
        this.tearDownEvents();
        this.tearDownViewListeners();
      };
    },
    { eager: false }
  );

  setupEvents() {
    // turn the events into an API for the feedbackBanner service
    // each created API function will be bound to this component
    // so that the component will automatically respond to the events
    this.events.forEach(eventObj => {
      let { eventName, handler } = eventObj;

      this.feedbackBanner.on(eventName, handler.bind(this));
    });
  }

  tearDownEvents() {
    this.bannerEvents?.forEach(eventObj => this.feedbackBanner.off(eventObj.eventName));
  }

  setupViewListeners() {
    visualViewport?.addEventListener('resize', this.onVisualViewportResize);
  }

  tearDownViewListeners() {
    visualViewport?.removeEventListener('resize', this.onVisualViewportResize);
  }

  async onVisualViewportResize() {
    let { bannerRef, inputRef } = this;
    let { visualViewport: { height: viewportHeight } = {} } = window || {};
    let { offsetHeight } = bannerRef || {};
    if (bannerRef && inputRef && offsetHeight >= viewportHeight) {
      bannerRef.style.height = `${viewportHeight}px`;
      bannerRef.style.minHeight = '0';
      bannerRef.style.overflowY = 'auto';
      await waitForLater(FOCUS_SCROLL_DELAY);
      inputRef.scrollIntoView({ behavior: 'instant' });
    }
  }

  @action
  onInputFocus(inputElement) {
    set(this, 'inputRef', inputElement);
  }

  @action
  onInputBlur() {
    set(this, 'inputRef', null);
    let { bannerRef } = this;
    if (bannerRef) {
      bannerRef.style.height = '';
      bannerRef.style.minHeight = '';
      bannerRef.style.overflowY = '';
    }
  }

  @action
  close() {
    this.feedbackBanner.dismiss();
  }
}
